<template>
    <Form @submit="handleSubmit" v-slot="{ errors }">
        <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Custom Fields</h1>
                    <button class="close_btn left_out" type="button" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="section_wpr">
                        <h3>
                            <span></span>
                            <button type="button" class="add_btn" @click="addField = true"><i class="fas fa-plus"></i>Add New</button>
                        </h3>
                    </div>
                    <div class="result_wpr new small m-0">
                        <div class="actions">
                            <ul>
                                <li @click="togglePerPageFilter()" class="optionDrops sort_list">
                                    Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="custom-field-per-page-filter" />
                                </li>
                                <li class="optionDrops search_area ml-auto active">
                                    <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                                    <button class="search_btn">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                                <!-- <li class="list_info">
                                    {{ customFields.from ? customFields.from : 0 }} - {{ customFields.to ? customFields.to : 0 }} of <span>{{ customFields.total ? customFields.total : 0 }}</span>
                                </li> -->
                            </ul>
                        </div>
                        <div v-if="customFieldLoader"><line-loader /></div>
                        <div class="scroll_table">
                            <table class="standard show_header">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="customFields.data && customFields.data.length">
                                    <tr v-for="(field, f) of customFields.data" :key="f">
                                        <td>
                                            <h4>{{ field.name }}</h4>
                                        </td>
                                        <td>{{ field.is_active ? 'Active' : 'Inactive' }}</td>
                                        <td class="action">
                                            <div class="dropdown" @click="toggleOption($event)">
                                                <i class="fas fa-ellipsis-v"></i>
                                                <div class="drp_wrapper">
                                                    <ul>
                                                        <li @click="handleEdit(field)">Edit</li>
                                                        <li @click="handleDelete(field)" class="danger">Delete</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="3" class="px-4 text-center">
                                            <div class="empty_box">
                                                <img src="@/assets/images/empty_state.svg">
                                                <h4>No Records Found</h4>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>{{ customFields.from ? customFields.from : 0 }} - {{ customFields.to ? customFields.to : 0 }} of {{ customFields.total ? customFields.total : 0 }}</li>
                        </ul>
                    </div>
                    <div class="pagination" v-show="customFields.total">
                        <pagination v-model="params.page" :pages="customFields.last_page" :range-size="0" @update:modelValue="handlePagination" />
                    </div>
                    <div v-if="addField" class="details_wpr">
                        <div class="cardItem_details">
                            <div class="header">
                                <button class="close_btn" type="button" @click="addField = false"><i class="fas fa-chevron-down"></i></button>
                                <h2>Add New Field</h2>
                            </div>
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Field Name</label>
                                        <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                            <Field autocomplete="off" type="text" v-model="form.name" name="name" placeholder="Enter field name here" rules="required" />
                                        </div>
                                        <ErrorMessage name="name" class="text-danger" />
                                    </div>
                                </div>
                                <label for="active_field" class="switch_option capsule_btn">
                                    <h5>Active</h5>
                                    <input type="checkbox" v-model="form.is_active" id="active_field" :true-value="1" :false-value="0" hidden>
                                    <div><span></span></div>
                                </label>
                            </div>
                            <div class="action_wpr fixed_footer">
                                <button :disabled="loader" type="button" class="btn cancel_btn" @click="addField = false">Cancel</button>
                                <button :disabled="loader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                </div>
            </div>
        </div>
    </Form>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'Contact Custom Field',

        data () {
            return {
                form: {
                    name: '',
                    is_active: 1,
                },
                params: {
                    per_page: 5,
                    search: '',
                    page: 1,
                },
                loader: false,
                addField: false,
                isTyping: false,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getCustomFields(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getCustomFields(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getCustomFields(vm.params);
                        }
                    }
                }
            },

            modelValue (value) {
                if (value) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    vm.resetForm();

                    setTimeout(function () {
                        vm.getCustomFields(vm.params);
                    }, 1000);
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            customFields: state => state.contactModule.customFields,
            customFieldLoader: state => state.contactModule.customFieldLoader,
        }),

        methods: {
            ...mapActions({
                getCustomFields: 'contactModule/getCustomFields',
                saveCustomField: 'contactModule/saveCustomField',
                deleteCustomField: 'contactModule/deleteCustomField',
            }),

            closeModal () {
                const vm = this;

                vm.addField = false;
                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form     = { name: '', is_active: 1 };
                vm.params   = { per_page: 5, search: '', page: 1 };
                vm.addField = false;
                vm.isTyping = false;
            },

            toggleOption (e) {
                let element = e.currentTarget;
                let trigEl = element.querySelector('.drp_wrapper');
                let trigAll = element.closest('tbody').querySelectorAll('.drp_wrapper');

                if (trigEl.classList.contains('active')) {
                    trigEl.classList.remove('active');
                } else {
                    for(let i = 0; i < trigAll.length; i++){
                        trigAll[i].classList.remove('active');
                    }

                    trigEl.classList.add('active');
                }
            },

            togglePerPageFilter () {
                const vm = this;
                vm.bulkAction = false;
                vm.statusList = false;
                const filter = vm.$refs['custom-field-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            handleSubmit (params, { setFieldError, resetForm }) {
                const vm = this;

                vm.loader = true;
                vm.form.setFieldError = setFieldError;

                vm.saveCustomField(vm.form).then((result) => {
                    if (result) {
                        vm.resetForm();
                        resetForm();
                        vm.getCustomFields(vm.params);
                        vm.getCustomFields({ active: 1, type: 'all' });
                        vm.addField = false;
                    }

                    vm.loader   = false;
                });
            },

            handleEdit (field) {
                const vm = this;

                vm.form.id        = field.id;
                vm.form.name      = field.name;
                vm.form.is_active = field.is_active;
                vm.addField       = true;
            },

            handleDelete (field) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', `You want to delete this field, you will not be able to recover this field.`);

                options.preConfirm = () => {
                                          return vm.deleteCustomField(field.id).then((result) => {
                                              if (result) {

                                                  vm.getCustomFields(vm.params);
                                                  vm.getCustomFields({ active: 1, type: 'all' });
                                              }
                                          });
                                      };

                Swal.fire(options);
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getCustomFields(vm.params);
            },
        },
    }
</script>

<style scoped>
    .modal .result_wpr.small table{
        min-width: auto !important;
    }
    @media(max-width: 767px){
        .result_wpr.small .actions{
            min-width: 100% !important;
        }
        .result_wpr.new.small .actions > ul li.search_area{
            width: 190px;
        }
        .result_wpr.new.small .actions > ul li.search_area input[type=text]{
            width: 100%;
            padding: 0 15px 0 0;
        }
    }
</style>
